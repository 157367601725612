import React, { useEffect } from "react"
import { navigate } from "@reach/router"
import Seo from "../components/Seo/seo"
import Layout from "../components/Layout/layout";
import {graphql, Link} from 'gatsby'
import styled from 'styled-components'
import PageBody from "../components/PageBody/pageBody";
import Image from 'gatsby-image'
import media from 'styled-media-query'

const Member = styled(({className, member}) => (
  <div className={`${className}`}>
    <Link className={`${className}__link`} to={member.path} state={{modal: true}}>
      <Image className={`${className}__portrait`} fluid={member.image.childImageSharp.fluid}/>
      {member.name}
    </Link>
  </div>
))`
  text-align: center;
  flex: 0 0 25%;
  
  ${media.lessThan('medium')`
    flex: 0 0 50%;
  `}
  
  &__link {
    display: block;
    margin: 0 80px 0 0;
    transition: 250ms opacity;
    color: ${props => props.theme.colors.primary};
    
    ${media.lessThan('medium')`
      margin: 0 12px 12px 12px;
    `}
  
    &:hover {
      text-decoration: none;
      opacity: .9;
    }
  }
  
  &:last-child {
    margin-right: 0;
  }
    
  &__portrait {
    margin-bottom: 8px;
  }
`

const Members = styled(({className, members}) => (
  <div className={className}>
    {members.edges.map(edge => (
      <Member member={edge.node}/>
    ))}
  </div>
))`
  display: flex;
  
  ${media.lessThan('medium')`
    flex-wrap: wrap;
  `}
`

const AboutUs = ({data}) => {
  return (
    <>
      <Seo slug=""/>
      <Layout>
        <PageBody h1="About Us">
          <section>
            <h2>Team</h2>
            <Members members={data.members}/>
          </section>
          <section>
            <h2>Background</h2>
            <p>Founded originally in 2018, as the result of a spin-off from Evolution Financial Group our roots are based in finance. That being said, the collective experience of our management group boasts 53 years of professional real estate experience whether that be in the form of investment, asset management, or brokerage.</p>
          </section>
          <section>
            <h2>Approach</h2>
            <p>As our name suggests, our view on real estate is definitely biased toward land ownership. Given the downward pressure on government yields, there remains a rush to yield producing products. With that being the case, we do not see many investments out there more secure and yet profitable than a ground lease. Even still, we continue to see a strong appetite for ground-up development projects here in Japan which further supports our land bias.</p>
          </section>
        </PageBody>
      </Layout>
    </>
  )
}

export const query = graphql`
  query AboutUsQuery {
    members: allMembersYaml {
      edges {
        node {
          name
          introduction
          path
          image {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

// export default AboutUs
export default () => {
    if (typeof window !== 'undefined') {
        window.location = '/';
    }

    return null;
}
